<template>
  <div class="d-flex flex-column flex-root">
    <div
        class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid" :style="bgColor"
        id="kt_login"
    >
      <!--begin::Aside-->
<!--      <div-->
<!--          class="login-aside d-flex flex-column flex-row-auto"-->
<!--          :style="bgColor"-->
<!--      >-->
<!--        <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-15">-->
<!--          <a href="#" class="text-center mb-10">-->


<!--            <img-->
<!--                src="/media/logos/score_logo.png"-->
<!--                class="max-h-70px"-->
<!--                alt="Score"-->

<!--            />-->

<!--          </a>-->
<!--          <h3-->
<!--              class="font-weight-bolder text-center font-size-h5 text-white" style="color: #ffffff"-->
<!--          >-->
<!--            ADMIN LOGIN & MANAGEMENT-->
<!--          </h3>-->
<!--        </div>-->
<!--        <div-->
<!--            class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"-->
<!--            :style="{ backgroundImage: `url(${backgroundImage})` }"-->
<!--        ></div>-->
<!--      </div>-->
      <!--begin::Aside-->

      <!--begin::Content-->
      <div
          class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >

        <div class="d-flex flex-column-fluid flex-center mt-lg-20 mt-lg-0">

          <router-view></router-view>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->

<script>
import {mapState} from "vuex";



export default {
  name: "auth",

  data() {
    return {
    setting:null
    }
  },
  methods: {

  },
  mounted() {

  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {

      if (this.login_bg_path) {
        return this.login_bg_path;
      }
      return process.env.BASE_URL + "/";
    },
    bgColor() {

      if (this.login_bg_color) {
        return "background-color:" + this.login_bg_color;
      }
      return "background-color: rgb(21, 35, 44)";
    },
  }
};
</script>
